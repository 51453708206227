import React, { useEffect } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import Head from 'next/head';
import { SSRConfig } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { fetchContextIfSsr } from '@frontastic/bridge/js/fetchContextIfSsr';
import LegacyFrontasticRenderer from '@frontastic/bridge/js/legacyRenderer';
import { useFormat } from 'helpers/hooks/useFormat';
import {
  createClient,
  LocaleStorage,
  PageDataResponse,
  PageFolder,
  RedirectResponse,
  ResponseError,
  useDarkMode,
} from 'frontastic';
import { tastics } from 'frontastic/tastics';
import styles from './slug.module.css';
import { IncomingMessage, ServerResponse } from 'http';
import { ParsedUrlQuery } from 'querystring';

type ErrorProps<T> = {
  data: { error: T };
  error: T;
  locale?: string;
};

type SuccessProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: PageDataResponse | RedirectResponse | ResponseError | null;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  userAgent: string;
  locale?: string;
  contextData: unknown | null;
} & SSRConfig;

type SlugProps = SuccessProps | ErrorProps<unknown>;

export default function Slug({ data, locale }: SlugProps) {
  LocaleStorage.locale = locale;

  const { applyTheme } = useDarkMode();

  useEffect(() => {
    // @ts-expect-error copied from starter
    applyTheme(data?.pageFolder?.configuration?.theme);
    // @ts-expect-error copied from starter
  }, [data?.pageFolder?.configuration]);

  const { formatMessage } = useFormat({ name: 'common' });

  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  // @ts-expect-error copied from starter
  if (!data!.ok && data!.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        {/* @ts-expect-error copied from starter */}
        <p className="mt-2 text-lg">{data!.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  return (
    <>
      <Head>
        <meta
          name="description"
          content={(data as PageDataResponse)?.pageFolder?.configuration?.seoDescription}
        />
      </Head>
      <LegacyFrontasticRenderer
        data={data as PageDataResponse}
        tastics={tastics}
        wrapperClassName={styles.gridWrapper}
      />
    </>
  );
}

export const putMasterStreamInPageFolderConfig = (pageFolder: PageFolder) => {
  if (!pageFolder.isDynamic) {
    return;
  }

  if (pageFolder.configuration.entity === undefined) {
    return;
  }

  for (const dataSourceConfiguration of pageFolder.dataSourceConfigurations) {
    const streamId =
      'streamId' in dataSourceConfiguration ? dataSourceConfiguration.streamId : dataSourceConfiguration.dataSourceId;
    if (streamId === '__master' && 'preloadedValue' in dataSourceConfiguration) {
      pageFolder.configuration.entity = dataSourceConfiguration.preloadedValue;
      if (pageFolder.configuration.entity.projectSpecificData?.nodeName) {
        pageFolder.name = pageFolder.configuration.entity.projectSpecificData?.nodeName;
      }
      if (pageFolder.configuration.entity.projectSpecificData?.nodeSeoDescription) {
        pageFolder.configuration.seoDescription =
          pageFolder.configuration.entity.projectSpecificData?.nodeSeoDescription;
      }
      if (pageFolder.configuration.entity.projectSpecificData?.nodeSeoTitle) {
        pageFolder.configuration.seoTitle = pageFolder.configuration.entity.projectSpecificData?.nodeSeoTitle;
      }
    }
  }
};

const fetchPageData = async (
  urlParams: ParsedUrlQuery,
  locale: string,
  query: ParsedUrlQuery,
  nextJsReq: IncomingMessage,
  nextJsRes: ServerResponse,
): Promise<RedirectResponse | PageDataResponse> => {
  const frontastic = createClient();
  const response = await frontastic.getRouteData(urlParams, locale, query, nextJsReq, nextJsRes);

  if (typeof response === 'object' && typeof response['target'] === 'string') {
    // Redirect response
    return response;
  }

  if (typeof response !== 'object' || response['ok'] === false || typeof response['data'] !== 'object') {
    return await frontastic.getRouteData({ ...urlParams, slug: ['__error'] }, locale, query, nextJsReq, nextJsRes);
  }

  return response;
};

export const getServerSideProps: GetServerSideProps<SlugProps> = async ({ params, locale, query, req, res }) => {
  LocaleStorage.locale = locale;

  const [data, translations, contextData] = await Promise.all([
    fetchPageData(params, locale, query, req, res),
    serverSideTranslations(locale, [
      'common',
      'cart',
      'product',
      'checkout',
      'account',
      'error',
      'success',
      'wishlist',
      'newsletter',
    ]),
    fetchContextIfSsr(req, res),
  ]);

  if ('target' in data) {
    return {
      redirect: {
        destination: data.target,
        statusCode: data.statusCode,
      } as Redirect,
    };
  }

  if (typeof data === 'object' && 'pageFolder' in data) {
    putMasterStreamInPageFolderConfig(data.pageFolder);

    if (data.pageFolder.isDynamic === true && data.pageFolder.pageFolderType === 'error') {
      res.statusCode = 404
    }
  }

  return {
    props: {
      data: data || null,
      userAgent: req.headers['user-agent'],
      locale: locale,
      contextData,
      ...translations,
    },
  };
};
